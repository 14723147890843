<template>
  <div>
    <v-card :loading="loading" class="mb-3">
      <v-card-title>Pemasukan</v-card-title>

      <v-divider></v-divider>

      <template v-if="!loading && transaction">
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>No Transaksi :</td>
                  <td>{{ transaction.trans_no }}</td>
                </tr>

                <tr>
                  <td>Referensi :</td>
                  <td>{{ transaction.ref }}</td>
                </tr>

                <tr>
                  <td>Tanggal :</td>
                  <td>{{ sql2date(transaction.trans_date) }}</td>
                </tr>

                <tr>
                  <td>Untuk :</td>
                  <td>
                    <span v-if="transaction.person_type_id == 0">
                      Lain - lain
                    </span>

                    <span v-if="transaction.person_type_id == 2">
                      Customer - {{ transaction.person_name }}
                    </span>

                    <span v-if="transaction.person_type_id == 3">
                      Supplier - {{ transaction.person_name }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </template>
    </v-card>

    <template v-if="!loading && transaction != null">
      <v-card class="mb-3" color="#15263b">
        <v-card-title class="pb-1">Total</v-card-title>

        <v-card-text>
          <h2>{{ currencyFormat(transaction.total) }}</h2>
        </v-card-text>
      </v-card>

      <v-card class="mb-3">
        <v-card-title>Items</v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <v-card v-for="item in transaction.items" :key="item.id" class="mb-3">
            <v-card-text class="pa-0">
              <v-simple-table>
                <template #default>
                  <tbody>
                    <tr>
                      <td class="fit">Akun</td>
                      <td class="text-right">
                        {{ item.account }} - {{ item.account_name }}
                      </td>
                    </tr>

                    <tr>
                      <td class="fit">Nilai</td>
                      <td class="text-right">
                        {{ currencyFormat(item.amount) }}
                      </td>
                    </tr>

                    <tr>
                      <td class="fit">Catatan</td>
                      <td class="text-right">{{ item.memo_ }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </template>
  </div>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    transaction: null,
  }),

  computed: {
    type() {
      return this.$route.params.type
    },
    transNo() {
      return this.$route.params.transNo
    },
  },

  mounted() {
    this.fetch()
  },

  methods: {
    currencyFormat(value) {
      return Intl.NumberFormat('id-ID', {
        currency: 'IDR',
      }).format(value)
    },
    fetch() {
      this.loading = true
      this.$http
        .get(`/accounting/banks/journals/${this.type}/${this.transNo}`)
        .then(({ data }) => {
          this.transaction = data
        })
        .catch((err) => {
          console.log(err)
        })
        .then(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.fit {
  width: 1%;
  white-space: nowrap;
}

table tr td {
  width: 50%;
}
</style>
